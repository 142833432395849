import React, { useEffect, useState } from 'react';
import API from '../API';

const uploadsUrl =
    process.env.NODE_ENV === "development"
        ? "http://nummirock.localhost/uploads"
        : "https://nummirock.mkoskinen.net/uploads";

function Schedule() {
    const [scheduleByDate, setScheduleByDate] = useState({});
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const fetchSchedule = async () => {
            try {
                const response = await API.getSchedule();
                setScheduleByDate(response);
            } catch (error) {
                console.error("Error fetching schedule", error);
            }
        };

        fetchSchedule();

        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 60000);

        setCurrentTime(new Date());

        return () => clearInterval(intervalId);

    }, []);  

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { weekday: 'long', day: 'numeric', month: 'numeric', year: 'numeric' };
        return new Intl.DateTimeFormat('fi-FI', options).format(date).replace('na', '');
    };

    const isCurrentlyPlaying = (startTime, endTime) => {
        const start = new Date(startTime);
        const end = new Date(endTime);
        return currentTime >= start && currentTime <= end;
    };

    return (
        <div className={"h-dvh my-16 text-white"}>
            <h1 className={"text-3xl text-center font-lalezar uppercase bg-black-to-transparent py-4 pb-0"}>Ohjelma</h1>
            <div>
            {Object.keys(scheduleByDate).map((date) => (
                <div key={date} className="">
                    <h2 className="text-xl font-semibold m-4 capitalize border-b border-b-rose-800">{formatDate(date)}</h2>
                    <ul>
                        {scheduleByDate[date].map((item, index) => {
                            const startTime = `${date}T${item.performanceStart}:00`;
                            const endTime = `${date}T${item.performanceEnd}:00`;
                            const highlightClass = isCurrentlyPlaying(startTime, endTime) ? 'bg-gray-900' : '';

                            return (
                                <li key={index} className={`mb-2 ${highlightClass} px-2`}>
                                    <div className="flex items-center p-2 rounded-lg">
                                        <img src={`${uploadsUrl}/${item.band.coverPhoto}`} alt={`${item.band.name} cover`} className="w-12 h-12 mr-4" />
                                        <div>
                                            <p className="text-lg font-medium">{item.band.name}</p>
                                            <p className="text-sm text-rose-600">{item.performanceStart} - {item.performanceEnd} | {item.stage}</p>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            ))}
            </div>
        </div>
    );
}

export default Schedule;