import axios from "axios";

const API_URL =
    process.env.NODE_ENV === "development"
        ? "http://nummirock.localhost/api"
        : "https://nummirock.mkoskinen.net/api";

axios.defaults.headers.post["Content-Type"] = "application/json";

const api = {
    getNowPlaying: async () => {
        try {
            const response = await axios.get(`${API_URL}/nowplaying`);

            return response.data;
        } catch (error) {
            console.error("Error fetching nowPlaying", error);
            throw error;
        }
    },

    getSchedule: async () => {
        try {
            const response = await axios.get(`${API_URL}/schedule`);

            return response.data;
        } catch (error) {
            console.error("Error fetching schedule", error);
            throw error;
        }
    },

    getArtists: async () => {
        try {
            const response = await axios.get(`${API_URL}/artists`);

            return response.data;
        } catch (error) {
            console.error("Error fetching artists", error);
            throw error;
        }
    },

    getArtist: async (id) => {
        try {
            const response = await axios.get(`${API_URL}/artists/${id}`);

            return response.data;
        } catch (error) {
            console.error("Error fetching artist", error);
            throw error;
        }
    },

    getStages: async () => {
        try {
            const response = await axios.get(`${API_URL}/stages`);

            return response.data;
        } catch (error) {
            console.error("Error fetching stages", error);
            throw error;
        }
    },

    getStage: async (id) => {
        try {
            const response = await axios.get(`${API_URL}/stages/${id}`);

            return response.data;
        } catch (error) {
            console.error("Error fetching stage", error);
            throw error;
        }
    },

    getUpcoming: async () => {
        try {
            const response = await axios.get(`${API_URL}/upcoming`);

            return response.data;
        } catch (error) {
            console.error("Error fetching upcoming", error);
            throw error;
        }
    },
};

export default api;
