import React, { useState, useEffect } from "react";

import API from "../API";

const uploadsUrl =
    process.env.NODE_ENV === "development"
        ? "http://nummirock.localhost/uploads"
        : "https://nummirock.mkoskinen.net/uploads";

function Home() {
    const [nowPlaying, setNowPlaying] = useState(null);
    const [coverPhoto, setCoverPhoto] = useState(null);
    const [upcoming, setUpcoming] = useState([]);

    useEffect(() => {
        const fetchNowPlaying = async () => {
            try {
                const response = await API.getNowPlaying();
                setNowPlaying(response);
                setCoverPhoto(`${uploadsUrl}/${response.band.coverPhoto}`);
            } catch (error) {
                console.error("Error fetching nowPlaying", error);
            }
        };

        const fetchUpcoming = async () => {
            try {
                const response = await API.getUpcoming();
                setUpcoming(response);
            } catch (error) {
                console.error("Error fetching upcoming", error);
            }
        };

        // Initial fetch
        fetchNowPlaying();
        fetchUpcoming();

        // Set interval to fetch every 1 minute
        const intervalId = setInterval(fetchNowPlaying, 60000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className={"h-dvh my-16"}>
            {nowPlaying ? (
                <div className={"text-white h-3/5"}>
                    <div
                        className={"h-full relative"}
                        style={{
                            backgroundImage: `url(${coverPhoto})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                        <h1 className={"text-3xl text-center font-lalezar uppercase bg-black-to-transparent p-5"}>
                            Now Playing
                        </h1>
                        <div className={"pt-6 absolute bottom-0 text-center w-full bg-transparent-to-black"}>
                            <div className={"text-3xl font-lalezar"}>{nowPlaying.band.name}</div>
                            <div className={"text-xl text-red-500"}>
                                {nowPlaying.stage} {nowPlaying.performanceStart}-{nowPlaying.performanceEnd}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}
            <div className={"text-white p-5 mb-10"}>
                <h1 className={"text-3xl font-lalezar uppercase text-gray-300"}>Next...</h1>
                <ul>
                    {upcoming.map((upcoming) => (
                        <li key={upcoming.id} className={"py-3 flex"}>
                            <div 
                                className={"rounded"}
                            style={{
                                backgroundImage: `url(${uploadsUrl + '/' + upcoming.band.coverPhoto})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                width: "100px",
                                height: "75px",
                                marginRight: "1rem"
                            }}>
                            </div>
                            <div>
                                <div className={"text-2xl"}>{upcoming.band.name}</div>
                                <div className={"text-base text-rose-600"}>
                                    {upcoming.stage}<br/>{upcoming.performanceStart}-{upcoming.performanceEnd}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Home;
