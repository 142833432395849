// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./components/Home";
import Schedule from "./components/Schedule";

import Logo from "./john-smith-logo.svg";

function App() {
    return (
        <Router>
            <div className={"bg-black flex flex-col h-dvh"}>
                <header className={"bg-red-950 text-white p-5 w-full flex justify-center fixed z-10"}>
                    <img src={Logo} alt="John Smith Logo" className={"w-32"} />
                </header>
                <main className={"flex-1 overflow-y-auto"}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/schedule" element={<Schedule />} />
                    </Routes>
                </main>
                <nav className={" bg-red-950 w-full bottom-0"}>
                    <ul className={"flex text-white"}>
                        <li className={"px-5 py-3"}>
                            <Link to="/">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                                    />
                                </svg>
                            </Link>
                        </li>
                        <li className={"px-5 py-3"}>
                            <Link to="/schedule">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="size-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                                    />
                                </svg>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </Router>
    );
}

export default App;
